var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-popup',{staticClass:"user-edit-popup",attrs:{"id":"popup","title":"Product Update","active":_vm.open,"buttons-hidden":true},on:{"close":function($event){_vm.open = false},"update:active":function($event){_vm.open=$event}}},[(_vm.is_loading_completed)?_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onClickSave)}}},[_c('div',{staticClass:"vx-row mb-3"},[_c('div',{staticClass:"vx-col md:w-full w-full mb-2"},[_c('small',{staticClass:"label"},[_vm._v("Name")]),_c('vs-input',{staticClass:"w-full",attrs:{"name":"name","type":"text","label":""},model:{value:(_vm.item_data.Name),callback:function ($$v) {_vm.$set(_vm.item_data, "Name", $$v)},expression:"item_data.Name"}})],1),_c('div',{staticClass:"vx-col md:w-full w-full"},[_c('vs-textarea',{staticClass:"w-full mb-0",attrs:{"rows":"6","label":"Description"},model:{value:(_vm.item_data.Description),callback:function ($$v) {_vm.$set(_vm.item_data, "Description", $$v)},expression:"item_data.Description"}})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full md:mb-0"},[_c('ValidationProvider',{attrs:{"vid":"name","name":"name","rules":"age_restriction"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label"},[_vm._v("Age Restriction")]),_c('vs-input',{staticClass:"w-full",attrs:{"name":"name","type":"number","label":""},model:{value:(_vm.item_data.AgeRestriction),callback:function ($$v) {_vm.$set(_vm.item_data, "AgeRestriction", $$v)},expression:"item_data.AgeRestriction"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full md:mb-0"},[_c('ValidationProvider',{attrs:{"vid":"StorageTemperature","name":"StorageTemperature","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label"},[_vm._v("Storage Temperature")]),_c('v-select',{attrs:{"label":"Name","options":_vm.storageTemperatures,"disabled":_vm.storageTemperatures.length === 0,"reduce":function (Code) { return Code.Id; },"dir":_vm.$vs.rtl ? 'rtl' : 'ltr'},model:{value:(_vm.item_data.StorageTemperature),callback:function ($$v) {_vm.$set(_vm.item_data, "StorageTemperature", $$v)},expression:"item_data.StorageTemperature"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full md:mb-0"},[_c('ValidationProvider',{attrs:{"vid":"storageRequirement","name":"brand","storageRequirement":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label"},[_vm._v("Storage Requirement")]),_c('v-select',{attrs:{"label":"Name","options":_vm.storageRequirements,"disabled":_vm.storageRequirements.length === 0,"reduce":function (Code) { return Code.Id; },"dir":_vm.$vs.rtl ? 'rtl' : 'ltr'},model:{value:(_vm.item_data.StorageRequirement),callback:function ($$v) {_vm.$set(_vm.item_data, "StorageRequirement", $$v)},expression:"item_data.StorageRequirement"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full md:mb-0"},[_c('ValidationProvider',{attrs:{"vid":"brand","name":"brand","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label"},[_vm._v("Brand")]),_c('v-select',{attrs:{"taggable":"","clearable":false,"placeholder":"Select a Brand","label":"Name","reduce":function (option) { return option.Name; },"options":_vm.brands,"disabled":_vm.brands.length === 0,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr'},model:{value:(_vm.item_data.Brand),callback:function ($$v) {_vm.$set(_vm.item_data, "Brand", $$v)},expression:"item_data.Brand"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full md:mb-0"},[_c('ValidationProvider',{attrs:{"vid":"UsageMethod","name":"UsageMethod","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label"},[_vm._v("Usage Methods")]),_c('v-select',{attrs:{"label":"Name","multiple":"","reduce":function (option) { return option.Id; },"options":_vm.usageMethods,"disabled":_vm.usageMethods.length === 0,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr'},model:{value:(_vm.item_data.UsageMethod),callback:function ($$v) {_vm.$set(_vm.item_data, "UsageMethod", $$v)},expression:"item_data.UsageMethod"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full md:mb-4 mt-0"},[_c('small',{staticClass:"label"},[_vm._v("E pharmacy Status")]),_c('p',{staticClass:"h-3"}),_c('div',{staticClass:"centerx"},[_c('span',{staticClass:"mr-6"},[_c('vs-radio',{attrs:{"vs-name":"x","vs-value":true},model:{value:(_vm.item_data.EPharmacyStatus),callback:function ($$v) {_vm.$set(_vm.item_data, "EPharmacyStatus", $$v)},expression:"item_data.EPharmacyStatus"}},[_vm._v("Yes")])],1),_c('span',{staticClass:"mr-6"},[_c('vs-radio',{attrs:{"vs-name":"x","vs-value":false},model:{value:(_vm.item_data.EPharmacyStatus),callback:function ($$v) {_vm.$set(_vm.item_data, "EPharmacyStatus", $$v)},expression:"item_data.EPharmacyStatus"}},[_vm._v("No")])],1),_c('span',{staticClass:"mr-6"},[_c('vs-radio',{attrs:{"vs-name":"x","vs-value":null},model:{value:(_vm.item_data.EPharmacyStatus),callback:function ($$v) {_vm.$set(_vm.item_data, "EPharmacyStatus", $$v)},expression:"item_data.EPharmacyStatus"}},[_vm._v("N/A")])],1)])]),_c('div',{staticClass:"vx-col w-full"},[_c('vs-textarea',{staticClass:"w-full mb-0",attrs:{"label":"Reason for Use","rows":"3"},model:{value:(_vm.item_data.ReasonForUse),callback:function ($$v) {_vm.$set(_vm.item_data, "ReasonForUse", $$v)},expression:"item_data.ReasonForUse"}})],1),_c('div',{staticClass:"vx-col md:w-full w-full mt-0 mb-4"},[_c('ValidationProvider',{attrs:{"vid":"tag","name":"tag","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"label"},[_vm._v("Tags")]),_c('v-select',{attrs:{"label":"Name","multiple":"","taggable":"","push-tags":"","options":_vm.tags,"disabled":_vm.tags.length === 0},model:{value:(_vm.item_data.Tag),callback:function ($$v) {_vm.$set(_vm.item_data, "Tag", $$v)},expression:"item_data.Tag"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full md:mb-0"},[_c('vs-textarea',{staticClass:"w-full mb-0",attrs:{"rows":"3","label":"Usage Instructions"},model:{value:(_vm.item_data.UsageInstruction),callback:function ($$v) {_vm.$set(_vm.item_data, "UsageInstruction", $$v)},expression:"item_data.UsageInstruction"}})],1)]),_c('div',{staticClass:"flex flex-wrap items-center justify-end mt-8"},[_c('vs-button',{staticClass:"ml-auto mt-2",attrs:{"id":"save_change","button":"submit"}},[_vm._v("Save Changes")])],1)])]}}],null,false,1047661513)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
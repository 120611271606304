<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title="Product Update"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <ValidationObserver
      v-if="is_loading_completed"
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <form @submit.prevent="handleSubmit(onClickSave)" autocomplete="off">
        <div class="vx-row mb-3">
          <div class="vx-col md:w-full w-full mb-2">
            <small class="label">Name</small>
            <vs-input
              v-model="item_data.Name"
              class="w-full"
              name="name"
              type="text"
              label=""
            />
          </div>
          <div class="vx-col md:w-full w-full">
            <vs-textarea
              class="w-full mb-0"
              rows="6"
              label="Description"
              v-model="item_data.Description"
            />
          </div>
          <div class="vx-col md:w-1/2 w-full md:mb-0">
            <ValidationProvider
              vid="name"
              name="name"
              rules="age_restriction"
              v-slot="{ errors }"
            >
              <small class="label">Age Restriction</small>
              <vs-input
                v-model="item_data.AgeRestriction"
                class="w-full"
                name="name"
                type="number"
                label=""
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full md:mb-0">
            <ValidationProvider
              vid="StorageTemperature"
              name="StorageTemperature"
              rules=""
              v-slot="{ errors }"
            >
              <small class="label">Storage Temperature</small>
              <v-select
                label="Name"
                v-model="item_data.StorageTemperature"
                :options="storageTemperatures"
                :disabled="storageTemperatures.length === 0"
                :reduce="(Code) => Code.Id"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <!--          <template v-slot:option="option">-->
                <!--            <span>{{option.Name[0].Value}}</span>-->
                <!--          </template>-->
              </v-select>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full md:mb-0">
            <ValidationProvider
              vid="storageRequirement"
              name="brand"
              storageRequirement=""
              v-slot="{ errors }"
            >
              <small class="label">Storage Requirement</small>
              <v-select
                label="Name"
                v-model="item_data.StorageRequirement"
                :options="storageRequirements"
                :disabled="storageRequirements.length === 0"
                :reduce="(Code) => Code.Id"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <!--          <template v-slot:option="option">-->
                <!--            <span>{{option.Name[0].Value}}</span>-->
                <!--          </template>-->
              </v-select>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full md:mb-0">
            <ValidationProvider
              vid="brand"
              name="brand"
              rules=""
              v-slot="{ errors }"
            >
              <small class="label">Brand</small>
              <v-select
                taggable
                :clearable="false"
                placeholder="Select a Brand"
                label="Name"
                v-model="item_data.Brand"
                :reduce="(option) => option.Name"
                :options="brands"
                :disabled="brands.length === 0"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <!--          <template v-slot:option="option">-->
                <!--            <span>{{option.Name}}</span>-->
                <!--          </template>-->
              </v-select>
            </ValidationProvider>
          </div>
          <!--      :get-option-label="({Id, Code}) => `${Code} - ${Id}`"-->
          <div class="vx-col md:w-1/2 w-full md:mb-0">
            <ValidationProvider
              vid="UsageMethod"
              name="UsageMethod"
              rules=""
              v-slot="{ errors }"
            >
              <small class="label">Usage Methods</small>
              <v-select
                label="Name"
                multiple
                v-model="item_data.UsageMethod"
                :reduce="(option) => option.Id"
                :options="usageMethods"
                :disabled="usageMethods.length === 0"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <!--          <template v-slot:option="option">-->
                <!--            <span>{{option.Name[0].Value}}</span>-->
                <!--          </template>-->
              </v-select>
            </ValidationProvider>
          </div>
          <div class="vx-col md:w-1/2 w-full md:mb-4 mt-0">
            <small class="label">E pharmacy Status</small>
            <p class="h-3"></p>
            <!--              <vs-checkbox v-model="item_data.EPharmacyStatus">E Pharmacy Status</vs-checkbox>-->
            <div class="centerx">
              <span class="mr-6">
                <vs-radio
                  vs-name="x"
                  v-model="item_data.EPharmacyStatus"
                  :vs-value="true"
                  >Yes</vs-radio
                >
              </span>
              <span class="mr-6">
                <vs-radio
                  vs-name="x"
                  v-model="item_data.EPharmacyStatus"
                  :vs-value="false"
                  >No</vs-radio
                >
              </span>
              <span class="mr-6">
                <vs-radio
                  vs-name="x"
                  v-model="item_data.EPharmacyStatus"
                  :vs-value="null"
                  >N/A</vs-radio
                >
              </span>
            </div>
          </div>
          <div class="vx-col w-full">
            <!--              <small class="label">Reason for Use</small>-->
            <!--              <vs-input v-model="item_data.ReasonForUse" class="w-full" name="name" type="text" label=""/>-->
            <vs-textarea
              class="w-full mb-0"
              label="Reason for Use"
              rows="3"
              v-model="item_data.ReasonForUse"
            />
          </div>
          <div class="vx-col md:w-full w-full mt-0 mb-4">
            <ValidationProvider
              vid="tag"
              name="tag"
              rules=""
              v-slot="{ errors }"
            >
              <small class="label">Tags</small>
              <!-- :reduce="(option) => option.Id" -->
              <v-select
                label="Name"
                multiple
                taggable
                push-tags
                v-model="item_data.Tag"
                :options="tags"
                :disabled="tags.length === 0"
              />
            </ValidationProvider>
          </div>
          <div class="vx-col w-full md:mb-0">
            <!--              <small class="label">Usage Instructions</small>-->
            <!--              <vs-input v-model="item_data.UsageInstruction" class="w-full" name="name" type="text" label=""/>-->
            <vs-textarea
              class="w-full mb-0"
              rows="3"
              label="Usage Instructions"
              v-model="item_data.UsageInstruction"
            />
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-end mt-8">
          <vs-button id="save_change" button="submit" class="ml-auto mt-2"
            >Save Changes</vs-button
          >
          <!--            <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
        </div>
      </form>
    </ValidationObserver>
  </vs-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ProductRepository = RepositoryFactory.get('product');
// const ClassifierRepository = RepositoryFactory.get('classifier');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

function initialState() {
  return {
    open: false,
    name: '',
    productId: null,
    item_data: {
      Tag: [],
    },
    is_loading_completed: false,
  };
}

export default {
  name: 'UpdateNodePopUp',
  components: {
    'v-select': vSelect,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapState('product', [
      'brands',
      'storageTemperatures',
      'storageRequirements',
      'usageMethods',
      'tags',
    ]),
    arrTagsId() {
      if (!this.item_data.Tag) {
        return [];
      }
      return this.item_data.Tag.filter((v) => v.Id !== undefined).map(
        (v) => v.Id,
      );
    },
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    ...mapActions('product', ['fetchCommonClassifiers']),
    async openPopup(id) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      this.productId = id;

      this.$vs.loading();
      await this.fetchCommonClassifiers();
      this.$vs.loading.close();

      await this.loadProduct();
    },
    async onClickSave() {
      const vue = this;
      this.$vs.loading();
      try {
        let updatedData = { ...this.item_data };
        updatedData.Tag = this.arrTagsId;
        updatedData.NewTag = this.getNewTags();

        await ProductRepository.update(this.productId, updatedData);
        this.$vs.loading.close();
        this.showSuccessMessage('Product update successfully!');
        this.open = false;
        await vue.$parent.dataRefresh();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.showErrorMessage('Something went wrong!');
      }
      this.$vs.loading.close();
    },
    async loadProduct() {
      this.$vs.loading();
      const data = (await ProductRepository.details(this.productId)).data[0];
      this.item_data.Name = data.Name.find((n) => n.Language === 'EST').Value;
      this.item_data.Tag = data.Tag
        ? data.Tag.map((m) => {
            return this.tags.find((tag) => tag.Id === m.Id);
          })
        : null;
      //{m.Name = m.Name[0].Value; return m;}
      this.item_data.Description = data.Description
        ? data.Description.Name.find((n) => n.Language === 'EST').Value
        : null;
      this.item_data.AgeRestriction = data.AgeRestriction;
      this.item_data.StorageTemperature = data.StorageTemperature
        ? data.StorageTemperature.Id
        : null;
      this.item_data.StorageRequirement = data.StorageRequirement
        ? data.StorageRequirement.Id
        : null;
      this.item_data.Brand = data.Brand ? data.Brand.Name[0].Value : null;
      this.item_data.UsageMethod = data.UsageMethod
        ? data.UsageMethod.map((m) => m.Id)
        : [];
      this.item_data.ReasonForUse = data.ReasonForUse
        ? data.ReasonForUse.Name[0].Value
        : null;
      this.item_data.UsageInstruction = data.UsageInstruction
        ? data.UsageInstruction.Name[0].Value
        : null;
      this.item_data.EPharmacyStatus = data.EPharmacyStatus;
      this.$vs.loading.close();
      this.is_loading_completed = true;
    },
    getNewTags() {
      if (!this.item_data.Tag) {
        return []
      }

      let newTagsArr = this.item_data.Tag.filter((item) => {
        return item.Id === undefined;
      }).map((item) => item.Name);

      return newTagsArr;
    },
  },
};
</script>

<style scoped>
</style>
